import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Nyc from '../../images/nyc.png'
import Jie from '../../images/jie.png'
import Pos from '../../images/pos.png'
import ehotel from '../../images/ehotel.png'
import Fabiano from '../../images/fabiano.png'
import FO from '../../images/fo.png'
import './index.scss'
const Projects = () => {
    return (
        <div className="container projects-page pl-60 position-relative mt-lg-5">
            <div className="row justify-content-center">
	        	<div className="col-md-4 content">
	        	    <h2 className="text-center text-white">NYC Health + Hospitals</h2>
					<div className="screen">
						<img src={Nyc} className="img-fluid" alt="NYC" />
					</div>
                    <ul>
                        <li className="text-center"><a rel="noreferrer" href='https://www.nychealthandhospitals.org/' target='_blank'>www.nychealthandhospitals.org</a></li>     
                    </ul>
	        	</div>
	        	<div className="col-md-4 content">
	        	    <h2 className="text-center text-white">Jie Flowers</h2>
					<div className="screen">
						<img src={Jie} className="img-fluid" alt="Jie" />
					</div>
                    <ul>
                        <li className="text-center"><a rel="noreferrer" href='https://www.jieflowers.com' target='_blank'>www.jieflowers.com</a></li>     
                    </ul>
	        	</div>
	        	<div className="col-md-4 content">
	        	    <h2 className="text-center text-white">POS</h2>
					<div className="screen">
						<img src={Pos} className="img-fluid" alt="POS" />
					</div>
                    <ul>
                        <li className="text-center"><a rel="noreferrer" href='https://orderonline.my/pos' target='_blank'>https://orderonline.my/pos</a></li>     
                    </ul>
	        	</div>
			</div>
            <div className="row justify-content-center my-5 pb-5">
	        	<div className="col-md-4 content">
	        	    <h2 className="text-center text-white">eHotel Software</h2>
					<div className="screen">
						<img src={ehotel} className="img-fluid" alt="eHotel" />
					</div>
                    <ul>
                        <li className="text-center"><a rel="noreferrer" href='https://orderonline.my/demo.ehotel/' target='_blank'>https://orderonline.my/demo.ehotel</a></li>     
                    </ul>
	        	</div>
	        	<div className="col-md-4 content">
	        	    <h2 className="text-center text-white">First Online</h2>
					<div className="screen">
						<img src={FO} className="img-fluid" alt="FO" />
					</div>
                    <ul>
                        <li className="text-center"><a rel="noreferrer" href='https://www.firstonline.com.my' target='_blank'>www.firstonline.com.my</a></li>     
                    </ul>
	        	</div>
	        	<div className="col-md-4 content">
	        	    <h2 className="text-center text-white">Fabiano Ricco</h2>
					<div className="screen">
						<img src={Fabiano} className="img-fluid" alt="Fabiano" />
					</div>
                    <ul>
						<li className="text-center"><a rel="noreferrer" href='https://orderonline.my/fabiano/' target='_blank'>https://orderonline.my/fabiano/</a></li>     
                    </ul>
	        	</div>
			</div>
        </div>
    )
}
export default Projects;