import './index.scss'
const AnimatedLetter = ({letterClass, strArray, idx}) => {

    return (
        <span>
            {
                strArray.map((Char,i) => (
                    <span key={Char + i} className={`${letterClass} _${i + idx}`}>
                    {Char}
                    </span>
                ))
            }
        </span>
    )
}

export default AnimatedLetter