import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCss3, faWordpress, faHtml5, faJsSquare, faReact,faQuora } from '@fortawesome/free-brands-svg-icons';
import Loader from 'react-loaders';
const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        const loadData = async () => {
        return setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 3000)
    };
    loadData();
      }, [])
    return (
        <>
        <div className='container about-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters letterClass={letterClass} strArray={['A','b','o','u','t','','m','e']} idx={15} />                    
                </h1>
                <p>With over 10 years of  IT experience which includes 3 years of React JS Development, I have honed my skills in React JS, Redux, Bootstrap 5, HTML, CSS, LESS/SASS, Figma, Adobe Photoshop, Adobe XD, Adobe Illustrator, JavaScript, jQuery, SAP Knockout JS, WordPress and SAP CPQ.</p>                    	
                <p>My specialization lies in crafting full-fledged websites from the ground up, accompanied by innovative graphic design solutions. I bring a wealth of expertise to the table and am eager to share it with your esteemed team and valuable clients.</p> 
            </div>
            <div className='stage-cube-cont'>
                <div className='cubespinner'>
                    <div className='face1'>
                        <FontAwesomeIcon icon={faHtml5} color='#f06529'/>
                    </div>
                    <div className='face2'>
                        <FontAwesomeIcon icon={faCss3} color='#28a4d9'/>
                    </div>
                    <div className='face3'>
                        <FontAwesomeIcon icon={faReact} color='#5ed4f4'/>
                    </div>
                    <div className='face4'>
                        <FontAwesomeIcon icon={faJsSquare} color='#efd81d'/>
                    </div>
                    <div className='face5'>
                        <FontAwesomeIcon icon={faWordpress} color='#DD0031'/>
                    </div>
                    <div className='face6'>
                        <FontAwesomeIcon icon={faQuora} color='#ec4d28'/>
                    </div>
                </div>
            </div>
        </div>
        <Loader type='packman' />
        </>
    )
}

export default About