import './index.scss'
import LogoS from '../../../assets/images/myphoto.jpg'
const Logo = () => {
return (
    <div className='logo-container'>
        <img src={LogoS} className="img-fluid" alt='myphoto' />
        {/*<div className='bg'>
            <span className='jb'>Skw</span>
        </div> */}
    </div>
)
}
export default Logo;