import { Link, NavLink } from 'react-router-dom'
import './index.scss'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Logos from '../../assets/images/logo-s.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faGear, faUser, faLightbulb, faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faStackOverflow, faQuora  } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => {
    return (
    <div className='nav-bar react-navbar'>  
        <div className='mobile-nav fixed-top position-relative'>      
            <Link className='logo' to='/'>
            <img src={Logos} alt="logo" />
            <img className='sub-logo d-none' src={LogoSubtitle} alt="Sublogo" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon={faBars} color="#ffd700"/>
            </button>
        </div>
        <nav className='collapse navbar-collapse' id="navbarNavDropdown">
            <NavLink exact="true" activeclassname="active" to="/">
                <FontAwesomeIcon icon={faHome} color="#4d4d4d"/>
            </NavLink>
            <NavLink exact="true" className="about-link" to="/about">
                <FontAwesomeIcon icon={faUser} color="#4d4d4d"/>
            </NavLink>
            <NavLink exact="true" className="skills-link" to="/skills">
                <FontAwesomeIcon icon={faGear} color="#4d4d4d"/>
            </NavLink>
            <NavLink exact="true" className="portfolio-link" to="/projects">
                <FontAwesomeIcon icon={faLightbulb} color="#4d4d4d"/>
            </NavLink>
            <NavLink exact="true" className="contact-link" to="/contact">
                <FontAwesomeIcon icon={faEnvelope} color="#4d4d4d"/>
            </NavLink>
        </nav>
        <ul>
            <li><a rel="noreferrer" href='https://linkedin.com/in/saravanawebtech' target='_blank'><FontAwesomeIcon icon={faLinkedin} color="#4d4d4d"/></a></li>        
            <li><a rel="noreferrer" href='https://stackoverflow.com/users/6060849' target='_blank'><FontAwesomeIcon icon={faStackOverflow} color="#4d4d4d"/></a></li>
            <li><a rel="noreferrer" href='https://uiinnovators.quora.com/' target='_blank'><FontAwesomeIcon icon={faQuora} color="#4d4d4d"/></a></li>
        </ul>
    </div>
    )
}
export default Sidebar;