import LogTitle from '../../assets/images/logo-s.png'
import './index.scss';
import AnimatedLetter from '../AnimatedLetters';
import { useEffect, useState } from 'react';
import Logo from './Logo'
import Loader from 'react-loaders';
import Resume from '../../assets/images/saravana_profile.doc'
const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['a','r','a','v','a','n','a,']
    const jobArray = ['F','r','o','n','t','e','n','d','','D','e','v','e','l','o','p','e','r.']
    useEffect(() => {
        const loadData = async () => {
        return setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 4000)
    };
    loadData();
      }, [])
    return  (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i,</span>
                <br />
                <span className={`${letterClass} _13`}>I</span>
                <span className={`${letterClass} _14`}>'m</span>
                <img src={LogTitle} alt="developer"/>
                <AnimatedLetter letterClass={letterClass} strArray={nameArray} idx={15}/>
                <br/>
                <AnimatedLetter letterClass={letterClass} strArray={jobArray} idx={12}/>
                </h1>
                <h2>UI/UX Developer | SAP CPQ</h2>
                <a href={Resume} className="flat-button" download>DOWNLOAD PROFILE</a>
            </div>
        </div>
        <Logo />
        <Loader type='packman' />
        </>
    )
}
export default Home;
