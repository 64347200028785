import { useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import AnimatedLetters from "../AnimatedLetters";
import './index.scss'
import TagCloud from "TagCloud";
const container = '.content';
const texts = [
  'Bootstrap', 'HTML5', 'JavaScript', 
  'CSS3', 'Animation','Redux', 'RESTful APIs', 'SASS',
  'Knockout JS', 'SAP CPQ', 'Adobe Photoshop',
  'Figma', 'CSS','RWD','jQuery','Github','React JS', 'Axios', 'Routing'
];
const options = {
  radius: 300,
  // animation speed
  // slow, normal, fast
  maxSpeed: 'fast',
  initSpeed: 'fast',
  // 0 = top
  // 90 = left
  // 135 = right-bottom
  direction: 135,
  // interact with cursor move on mouse out
  keep: true
};
const Skills = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        TagCloud(container, texts, options);
        const loadData = async () => {
        return setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 3000)
    };
    loadData();
      }, [])
    return (
        <div className="container projects-page skills-page  about-page pl-60 position-relative mt-lg-5">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className='text-zone'>
                        <h1>
                            <AnimatedLetters letterClass={letterClass} strArray={['S','k','i','l','l','s','','&']} idx={15} /> <br/>
                            <AnimatedLetters letterClass={letterClass} strArray={['R','e','s','p','o','n','s','i','b','i','l','i','t','i','e','s']} idx={13} />                     
                        </h1>
                        <p>Experienced UI/UX Developer with over three years of hands-on experience in React for building dynamic and responsive web applications. Proficient in leveraging React.js libraries and tools to design user interfaces, manage state, and implement complex features</p>
                        <p>My proficiency extends to rapidly and effectively developing web pages using Bootstrap 5, HTML, CSS, LESS/SASS, Figma, Adobe Photoshop, Adobe XD, Adobe Illustrator, JavaScript, jQuery, SAP Knockout JS, WordPress and SAP CPQ, with expertise in ensuring cross-browser compatibility.</p>
                        <p>I excel in creating mobile-responsive websites and have a strong command in this area.</p>
                        <p>Furthermore, I have hands-on experience in utilizing Adobe tools such as Photoshop and Figma.</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className='main'>
                        <span id="content" className="content"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills;