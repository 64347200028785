import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import {useState,useEffect} from 'react';
import AnimatedLetters from '../AnimatedLetters'
import './index.scss';


const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    useEffect(() => {
        const loadData = async () => {
        return setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 3000)
    };
    loadData();
      }, [])
  const form = useRef();
  const [showModal,setShowModal] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_69k19lm', 'template_c7dsmof', form.current, {
        publicKey: 'JTHaZbta9wJ8qBSwE',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
          }, 3000);
          e.target.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };  
  
    return (            
        <div className='d-flex h-100'>
            <div className="card col-md-8 col-lg-6 mx-auto shadow-sm mb-2 mt-3 p-4 align-self-center contact-form shadow-none bg-transparent border-0">                 
                <div className='text-zone p-3'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['C','o','n','t','a','c','t','','M','e']} idx={5} />                    
                    </h1>
                </div>
                <div className="p-0">  
                    <form ref={form} onSubmit={sendEmail}>
                        <p>If you have any other requests or questions, please don't hesitate to contact me using the form below.</p>  
                        <div className="mb-3">
                        <input className="form-control shadow-none"
                            type="text"
                            name="from_name"
                            placeholder="Name"
                        />
                        </div>
                        <div className="mb-3">
                        <input className="form-control shadow-none"
                            type="email"
                            name="from_email" 
                            placeholder="Email"
                        />
                        </div>
                    <textarea name='message' rows={8} className="form-control shadow-none" placeholder="Message"></textarea>
                    <div className="mt-3">
                        <button className="btn btn-primary btn-custom" type="submit">Submit</button>
                        </div>
                    </form> 
                    
                    {showModal && (
                    <div className="card bg-success d-block mt-3" tabIndex={-1}>
                        <div className="card-body text-white">
                        "I have received your inquiry, I will get back to you as soon as possible.<br/> Thank you!"
                        </div>
                    </div>   
                            
                    )}
                </div> 
            </div>    
        </div>    
    );
  };


export default Contact;